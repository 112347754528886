import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import AdminLayout from "../layouts/Admin/Admin.jsx";
import {
  Api,
  MobsButton,
  MobsCardCollapse,
  MobsTable,
  Utils,
} from "../mobscomponents";

class Parceiros extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataset: [],
      tableMsg: "Carregando...",
    };

    this.bootstrapTableRef = React.createRef();
  }

  componentDidMount() {
    Api.req("Parceiros/Get")
      .then((e) => {
        if (e.status) {
          this.setState({
            dataset: e.retorno,
            tableMsg: "Não há dados na tabela",
          });
        } else {
          Utils.alert("Oops..", e.msg);
          this.setState({ tableMsg: "Não há dados na tabela" });
        }
      })
      .catch((e) => {
        Utils.alert("Oops..", e.toString());
        this.setState({ tableMsg: "Não há dados na tabela" });
      });
  }

  handleDeleteButtonClick = (value, row) => {
    Utils.alertConfirm(
      "Atenção",
      `Confirmar exclusão do cavalo '${row.Titulo}'?`,
      "warning",
      "Confirmar",
      "Não",
      (confirm) => {
        if (confirm) {
          Utils.loading();
          Api.req("Parceiros/Remove", null, [{ key: "Codigo", value: value }])
            .then((e) => {
              Utils.cancelLoading();
              if (e.status) {
                let dataset = this.state.dataset.filter(
                  (u) => u.Codigo !== value
                );
                this.setState({ dataset });
                Utils.alert("Yay!", e.msg, "success");
              } else {
                Utils.alert("Oops..", e.msg);
              }
            })
            .catch((e) => {
              Utils.cancelLoading();
              Utils.alert("Oops..", e.toString());
            });
        }
      }
    );
  }

  createButton = (value, row) => {
    return (
      <div>
          <MobsButton
              tooltip={"Editar Parceiro"}
              path={`/parceiros-formulario/${value}`}
              color={"info"}
              size={"sm"}
          >
              <i className="tim-icons icon-pencil" />
          </MobsButton>
        <MobsButton
          tooltip={"Excluir Parceiro"}
          onClick={() => this.handleDeleteButtonClick(value, row)}
          color={"danger"}
          size={"sm"}
        >
          <i className="tim-icons icon-simple-remove" />
        </MobsButton>
      </div>
    );
  };

  render() {
    const optionsPage = {
      hideSizePerPage: true,
      noDataText: this.state.tableMsg,
      beforeShowError: (type, msg) => {
        this.bootstrapTableRef.current.body.cancelEditCell();

        if (msg === false) return;

        Utils.alert("Atenção!", msg, "error");
      },
    };

    return (
      <>
        <AdminLayout
          {...this.props}
          content={
            <div className="content">
              <Row>
                <Col md="12">
                  <MobsCardCollapse
                    isStatic
                    title={
                      <>
                        Meus parceiros cadastrados
                        <MobsButton
                          className="btn-simple float-right"
                          path={`/parceiros-formulario`}
                          color={"info"}
                          size={"sm"}
                        >
                          Cadastrar
                        </MobsButton>
                      </>
                    }
                    body={
                      <>
                        <MobsTable
                          pagination
                          options={optionsPage}
                          refBootstrapTable={this.bootstrapTableRef}
                          dataSet={this.state.dataset}
                          tableFields={[
                            {
                              key: "Codigo",
                              label: "Código",
                              placeholder: "Pesquisar Código",
                              dataSort: true,
                              isKey: true,
                            },
                            {
                              key: "Titulo",
                              label: "Titulo",
                              placeholder: "Pesquisar Titulo",
                              dataSort: true,
                            },
                            {
                              key: "Link",
                              label: "Link",
                              placeholder: "Pesquisar Link",
                              dataSort: true,
                            },
                            {
                              key: "Codigo",
                              label: "#",
                              type: "buttons",
                              dataAlign: "right",
                              createButtons: this.createButton.bind(this),
                            },
                          ]}
                          optionsPage={optionsPage}
                        />
                      </>
                    }
                  />
                </Col>
              </Row>
            </div>
          }
        />
      </>
    );
  }
}

export default Parceiros;
