/* eslint-disable */
import { Component } from "react";
import Api from "./Api";
import Utils from "./Utils";

export default class MobsComponentForm extends Component {
  apiEndpoint = {};

  componentDidMount() {
    const param = this.props.match.params.id;
    if (param) {
      this.load(param);
    }
  }

  initializeEndpoints(endpoints){
    this.apiEndpoint = endpoints
  }

  load(index) {
    if (typeof this.apiEndpoint === 'undefined' || this.apiEndpoint.detail === undefined) {
      console.error("Cannot call Load method without a defined endpoint. See apiEndpoint.detail.");
    }

    Utils.loading();
    Api.req(this.apiEndpoint.detail, null, [{ key: 'Codigo', value: index }])
      .then((e) => {
        if (e.status) {
          this.formDidLoad(e.retorno);
        }
        else {
          Utils.alert("Oops..", e.msg);
        }

        Utils.cancelLoading();
      })
      .catch((e) => {
        Utils.cancelLoading();
        Utils.alert("Oops..", e.toString());
      });
  }

  formDidLoad(formData) {
    if(!formData || formData.length == 0)
      return;
    
    this.removeNullFields(formData);

    this.props.setValues(formData);
    this.props.setTouched(false);
  }

  removeNullFields(obj){
    var propNames = Object.getOwnPropertyNames(obj);
    for (var i = 0; i < propNames.length; i++) {
      var propName = propNames[i];
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }
}