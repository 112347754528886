import React, { PureComponent } from "react";
import { Input, FormFeedback } from "reactstrap";


class MobsInputSelect extends PureComponent {
  render() {
    let name = this.props.name;
    let errors = this.props.errors[name];

    return (
      <>
        <Input
          multiple={!!this.props.multiple ? true : false}
          name={this.props.name}
          type="select"
          ref={(c) => (this._input = c)}
          defaultValue={this.props.defaultValue}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={(e) => {
            this.props.onChange(e);
            this.props.onSelect && this.props.onSelect(e.target.value);
          }}
          onBlur={this.props.onBlur}
          disabled={this.props.disabled}
          valid={!errors}
          invalid={!!errors}
        >
          {this.props.children}
        </Input>
        {errors ? (
          <FormFeedback> {!!errors && errors} </FormFeedback>
        ) : (
          <label style={{ color: "#bbbbbb", marginLeft: 10 }}>
            {" "}
            {this.props.helper}{" "}
          </label>
        )}
      </>
    );
  }
}

export default MobsInputSelect;
