import React, { Component } from "react";
import MobsInput from "./MobsInput";
import { ChromePicker } from 'react-color';

export default class MobsColorPicker extends Component {
  constructor(props){
    super(props);

    this.state = {
      displayColorPicker: false
    }
  }

  toggleColorPicker(){
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  render() {
    let name = this.props.name;

    return (
      <>
        <MobsInput
          type="text"
          name={name}
          value={this.props.value}
          placeholder={this.props.placeholder}
          errors={this.props.errors}
          onClick={()=> this.toggleColorPicker()}
          onChange={this.props.onChange}
        />
        <div style={this.state.displayColorPicker === true ? {zIndex: 2} : { display: 'none' }}>
          <ChromePicker 
            color={this.props.value}
            onChangeComplete={
              (el) => {
                this.props.setFieldValue(name, el.hex);
              }
            }
          >
          </ChromePicker>
        </div>
      </>
    );
  }
}