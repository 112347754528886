import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import styled from 'styled-components';
import Swal from "sweetalert2";
import { Button, FormFeedback } from "reactstrap";

import audio from "../assets/img/audio-upload.png";
import video from "../assets/img/video-upload.png";

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};
const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  borderColor: '#00f2c3',
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};
const thumbLoaded = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  borderColor: '#0095f2',
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};
const thumbInner = {
  position: 'relative',
  display: 'flex',
  minWidth: '100%',
  overflow: 'hidden'
};
const img = {
  display: 'block',
  width: '100%',
  height: '100%'
};
const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
}
const imgCancel = {
  display: 'flex',
  flexDirection: 'row',
  right: 0,
  position: 'absolute',
  color: 'red'
};
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

//TODO: Implementar thumb de vídeo
export default class MobsDropzone extends Component {
  handleOnDropRejected(rejectedFiles) {
    if (!this.validateMaxFiles(rejectedFiles))
      return;

    let fileNames = [];
    let propMaxSize = this.props.maxSize;

    rejectedFiles.forEach(el => {
      if (!propMaxSize)
        return;

      if (el.size > propMaxSize) {
        fileNames.push(el.name);
      }
    });

    if (fileNames.length > 0)
      Swal.fire("Atenção!", `O tamanho do(s) arquivo(s) ${fileNames.join(', ')} excedeu o limite de ${(propMaxSize / 1000000).toFixed(2)} MB`, 'warning');
  }

  handleOnDrop(acceptedFiles) {
    if (!this.validateMaxFiles(acceptedFiles))
      return;
  }

  validateMaxFiles(files) {
    let value = this.props.value;
    if (!value)
      value = [];

    let totalFiles = value.length + files.length;
    if (totalFiles > this.props.maxFiles) {
      Swal.fire("Atenção!", this.props.maxFilesMessage, 'warning');
      return false;
    }

    return true;
  }

  handleDropAccepted(files) {
    let value = this.props.value;
    if (!value)
      value = [];

    let totalFiles = value.length + files.length;
    if (totalFiles > this.props.maxFiles)
      return;

    let currentlyUploaded = files.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));

    this.props.setFieldValue(this.props.name, [...currentlyUploaded,...value]);
  }

  renderData() {
    let value = this.props.value;

    if (!value) {
      let preview = this.props.preview
      if (!preview)
        return;

      return preview.map(f => { 
        let splitExt = f.split('.');
        let ext = splitExt[splitExt.length-1];
        console.log("IMAGEM PREVIEW", f);
        
        return (
        <div style={thumbLoaded}>
          <div style={thumbInner}>
            {(ext !== 'jpeg' || ext !== 'png') && ext === 'mp4' ?
              <img
                src={video}
                style={img}
                alt=''
              />
              : (ext !== 'jpeg' || ext !== 'png') && ext === 'mp3' ?
                <img
                  src={audio}
                  style={img}
                  alt=''
                />
                :
                <img
                  src={f}
                  style={img}
                  alt='Preview'
                />
            }
            <Button color="danger" close style={imgCancel} aria-label="Excluir" onClick={
              () => {
                let previewFiltered = preview.filter(x => x !== f);
                if (previewFiltered.length === 0) {
                  this.props.setFieldValue(this.props.previewName, undefined);
                } else {
                  this.props.setFieldValue(this.props.previewName, previewFiltered);
                }
              }
            } />
          </div>
        </div>

      )});
    }

    return value.map(f => (
      <div style={thumb}>
        <div style={thumbInner}>
          {(f.type !== 'image/jpeg' || f.type !== 'image/png') && f.type === 'video/mp4' ?
            <img
              src={video}
              style={img}
              alt=''
            />
            : (f.type !== 'image/jpeg' || f.type !== 'image/png') && f.type === 'audio/mp3' ?
              <img
                src={audio}
                style={img}
                alt=''
              />
              :
              <img
                src={f.preview}
                style={img}
                alt=''
              />
          }
          <Button color="danger" close style={imgCancel} aria-label="Excluir" onClick={
            () => {
              let valueFiltered = value.filter(u => u !== f);
              if (valueFiltered.length === 0) {
                this.props.setFieldValue(this.props.name, null);
              } else {
                this.props.setFieldValue(this.props.name, valueFiltered);
              }
            }
          }
          />
        </div>
      </div>
    )
    );
  }

  render() {
    return (
      <>
        <Dropzone {...this.props}
          onDrop={this.handleOnDrop.bind(this)}
          onDropAccepted={this.handleDropAccepted.bind(this)}
          onDropRejected={this.handleOnDropRejected.bind(this)}>
          {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => (
            <Container {...getRootProps({ isDragAccept, isDragReject, isDragActive })}>
              <input {...getInputProps()} />
              {this.props.children}
            </Container>
          )}
        </Dropzone>
        <FormFeedback style={{ display: 'block' }}>
          {(!!this.props.errors[this.props.name]) && this.props.errors[this.props.name]}
        </FormFeedback>
        <aside style={thumbsContainer}>
          {
            this.renderData()
          }
        </aside>
      </>
    );
  }
}
