import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { Input, FormFeedback } from "reactstrap";

export default class MobsInputNumber extends Component {

  render() {
    let name = this.props.name;

    let errors = this.props.errors[name];

    return (
      <>
        <NumberFormat
          name={name}
          defaultValue={this.props.defaultValue}
          placeholder={this.props.placeholder}
          type={this.props.type}
          value={this.props.value}
          disabled={this.props.disabled}
          valid={!errors}
          onBlur={this.props.onBlur}
          invalid={!!errors}
          onValueChange={(values) => {
            const {floatValue} = values;
            this.props.setFieldValue(name, floatValue);
          }}
          mask={this.props.mask}
          format={this.props.format}
          prefix={this.props.prefix}
          suffix={this.props.suffix}
          allowLeadingZeros={this.props.allowLeadingZeros}
          decimalSeparator={this.props.decimalSeparator}
          decimalScale={this.props.decimalScale}
          thousandSeparator={this.props.thousandSeparator}
          thousandsGroupStyle={this.props.thousandsGroupStyle}
          displayType={this.props.displayType}
          allowNegative={this.props.allowNegative}
          fixedDecimalScale={this.props.fixedDecimalScale}
          isNumericString={this.props.isNumericString}
          customInput={Input}
        />
        {
          errors ? <FormFeedback> {(!!errors) && errors} </FormFeedback>
            :
            <label style={{ color: "#bbbbbb", marginLeft: 10 }}> {this.props.helper} </label>
        }
      </>
    );
  }
}