/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";

import "./assets/scss/black-dashboard-react.scss";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";
import routes from "./routes";
import Dashboard from "./views/Dashboard";
import { Auth } from "./mobscomponents";

import "../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
import "./assets/css/main.css";
import 'antd/dist/antd.css';

const PrivateRoute = ({ component: Component, ...rest }) => (

  <Route
    {...rest}
    render={(props) =>
      Auth.IsAuthenticated() ? (
        <Component {...props} />
      ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
    }
  />
);

ReactDOM.render(
  <BrowserRouter basename={'/'}>
    <Switch>
      {routes.map((value, key) => {
        if (!value.remove) {
          if (value.private) {
            return (<PrivateRoute key={value.path} path={value.path} component={() => value.render} />);
          } else {
            return (<Route key={value.path} path={value.path} component={() => value.render} />);
          }
        } else {
          let viewReturn = [];
          if (value.views) {
            value.views.map((valueViews, key2) => {
              if (valueViews.private) {
                viewReturn.push(<PrivateRoute path={valueViews.path} component={() => valueViews.render} />);
              } else {
                viewReturn.push(<Route path={valueViews.path} component={() => valueViews.render} />);
              }
            });
            return viewReturn;
          }
        }
      })}
      <PrivateRoute exact path="/" component={() => <Dashboard />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
