class Prefs {
    get(key){
        return JSON.parse(localStorage.getItem(key));
    }
    
    set(key, value){
        localStorage.setItem(key, JSON.stringify(value));
    }

    clear(key = null){
        if(key === null){
            localStorage.clear();
        } else {
            localStorage.removeItem(key);
        }
    }
}

export default new Prefs();
