/* eslint-disable */
import React from "react";
import MobsGeneralComponent from "../../../src/mobscomponents/MobsGeneralComponent.jsx";
import routes from "../../routes.js";
import logo from "../../assets/img/launch_screen.png";
import Prefs from "../../mobscomponents/Prefs";
import PerfectScrollbar from "perfect-scrollbar";

import { Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, Nav, Container } from "reactstrap";

import classNames from "classnames";
import { NavLink, Link } from "react-router-dom";

let ps;
export default class Admin extends MobsGeneralComponent {

  activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  }

  activeCollapse(propView) {
    for (let i = 0; i < propView.length; i++) {
      if (window.location.href.indexOf(propView[i].path) === -1)
        continue;

      return (window.location.href.indexOf(propView[i].path) > -1) ? "active" : "";
    }
  }

  Exit() {
    Prefs.clear();
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true
      });
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }

  getName() {
    let name = "Dashboard";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.path) > -1) {
        name = prop.name;
      }
      if (prop.views) {
        for (let i = 0; i < prop.views.length; i++) {
          if (window.location.href.indexOf(prop.views[i].path) > -1) {
            name = prop.views[i].name;
          }
        }
      }
    });
    return name;
  }

  createCollapseMenu(itens) {
    let view = [];
    itens.map((prop, key) => {
      view.push(
        <li
          className={this.activeRoute(prop.path)}
          key={key}
        >
          <NavLink
            to={prop.path}
            className="nav-link"
            activeClassName="active"
          >
            <span className="sidebar-mini-icon">{prop.miniName}</span>
            <p>{prop.name} </p>
          </NavLink>
        </li>
      );
    });
    return view;
  }

  toggleCollapse(prop) {
    if (!prop.state) {
      return prop.state = true;
    } else {
      return prop.state = false;
    }
  }

  render() {
    return (
      <>
        <div className="wrapper">
          <div className="sidebar" data={this.state.backgroundColor}>
            <div className="sidebar-wrapper" ref="sidebar">
              <div className="logo">
                <Link to={{
                  pathname: `/dashboard`
                }}
                  className="simple-text logo-mini"
                  onClick={this.toggleSidebar}>
                  <div className="logo-img">
                    <img src={logo} alt="react-logo" />
                  </div>
                </Link>
                <Link to={{
                  pathname: `/dashboard`
                }}
                  className="simple-text logo-normal"
                  onClick={this.toggleSidebar}>
                  Book of Stallion
                </Link>
              </div>
              <Nav>
                {routes.map((prop, key) => {
                  if (prop.redirect || !prop.showmenu) return null;
                  if (prop.collapse) {
                    return (
                      <li
                        className={this.activeCollapse(prop.views)}
                        key={key}
                      >
                        <NavLink
                          to={"#"}
                          data-toggle="collapse"
                          className="nav-link"
                          activeClassName="active"
                          aria-expanded={prop.state}
                          onClick={e => {
                            this.toggleCollapse(prop);
                          }}
                        >
                          <i className={prop.icon} />
                          <p>{prop.name}
                            <b className="caret" />
                          </p>
                        </NavLink>
                        <Collapse isOpen={prop.state}>
                          <ul className="nav">
                            {this.createCollapseMenu(prop.views)}
                          </ul>
                        </Collapse>
                      </li>
                    );
                  } else {
                    return (
                      <li
                        className={this.activeRoute(prop.path)}
                        key={key}
                      >
                        <NavLink
                          to={prop.path}
                          className="nav-link"
                          activeClassName="active"
                          onClick={this.toggleSidebar}
                        >
                          <i className={prop.icon} />
                          <p>{prop.name}</p>
                        </NavLink>
                      </li>
                    );
                  }
                })}
              </Nav>
            </div>
          </div>
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Navbar className={classNames("navbar-absolute navbar-transparent  navbar-white", this.state.color)} expand="lg" >
              <Container fluid>
                <div className="navbar-wrapper">
                  <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                    {this.getName()}
                  </NavbarBrand>
                </div>
                <Collapse navbar isOpen={this.state.collapseOpen}>
                  <Nav className="ml-auto" navbar>
                    <UncontrolledDropdown nav>
                      <DropdownToggle
                        caret
                        color="default"
                        data-toggle="dropdown"
                        nav
                        onClick={e => e.preventDefault()}
                      >
                        <div className="photo">
                          <img alt="..." src={require("../../assets/img/anime3.png")} />
                        </div>
                        <b className="caret d-none d-lg-block d-xl-block" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-navbar" right tag="ul">
                        <NavLink onClick={() => this.Exit()} to={"#"} tag="li">
                          <DropdownItem className="nav-item">Sair</DropdownItem>
                        </NavLink>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <li className="separator d-lg-none" />
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
            {this.props.content}
          </div>
        </div>
      </>
    );
  }
}
