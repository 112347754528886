/* eslint-disable */

import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Utils from "./Utils";
import NumberFormat from "react-number-format";


export default class MobsTable extends Component {
  defaultDataFormat(cell) {
    return cell;
  }

  dateDataFormat(cell) {
    return Utils.dateFormatStrToBR(cell);
  }

  dateTimeDataFormat(cell) {
    return Utils.datetimeFormatStrToBR(cell);
  }

  phoneDataFormat(cell) {
    return (
      <NumberFormat
        type={"text"}
        displayType={"text"}
        value={cell}
        format={cell.length > 10 ? "(##) ##### - ####" : "(##) #### - ####"}
      />
    );
  }

  createHeader() {
    let contentHeader = [];
    this.tableFields.forEach((e) => {
      let filter = {
        type: "TextFilter",
        delay: 500,
        placeholder: e.placeholder,
      };

      let dataFormat = e.dataFormat ? e.dataFormat : this.defaultDataFormat;

      if (e.type === "select") {
        filter = {
          type: "SelectFilter",
          delay: 500,
          placeholder: e.placeholder,
          options: e.options,
        };
      } else if (e.type === "buttons") {
        filter = null;
        dataFormat = e.createButtons;
      } else if (e.type === "date") {
        filter = {
          type: "DateFilter",
          delay: 500,
          placeholder: e.placeholder,
        };
        dataFormat = this.dateDataFormat;
      } else if (e.type === "phone") {
        dataFormat = this.phoneDataFormat;
      } else if (e.type === "number") {
        filter = {
          type: "NumberFilter",
          delay: 500,
          placeholder: e.placeholder,
        };
      } else if (e.type === "sort") {
        filter = null;
      } else if (e.type === "datetime") {
        filter = {
          type: "DateFilter",
          delay: 500,
          placeholder: e.placeholder,
        };
        dataFormat = this.dateTimeDataFormat;
      }

      contentHeader.push(
        <TableHeaderColumn
          key={e.key}
          dataField={e.key}
          filter={filter}
          isKey={e.isKey}
          dataSort={e.dataSort}
          dataFormat={dataFormat}
          width={e.width}
          dataAlign={e.dataAlign}
          editable={e.editable || false}
          customEditor={e.customEditor}
        >
          {e.label}
        </TableHeaderColumn>
      );
    });
    return contentHeader;
  }

  render() {
    this.tableFields = this.props.tableFields;

    return (
      <>
        <BootstrapTable
          data={this.props.dataSet}
          striped={this.props.striped ? this.props.striped : true}
          hover={this.props.hover ? this.props.hover : true}
          pagination={this.props.pagination}
          ref={this.props.refBootstrapTable}
          cellEdit={this.props.cellEdit}
          options={this.props.optionsPage}
        >
          {this.createHeader()}
        </BootstrapTable>
      </>
    );
  }
}
