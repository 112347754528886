module.exports = {
  contains(a, b) {
    return this.normalizeText(a).includes(this.normalizeText(b));
  },

  removeAllButNumbers(s) {
    if (!s) {
      return "";
    }

    return s.replace(/[^0-9]/g, '');
  },
   
  datetimeFormatStrToBR(date){
      let datetimeSplit = date.split(' ');
      let dateSplit = datetimeSplit[0].split('-');

      return dateSplit[2]+"/"+dateSplit[1]+"/"+dateSplit[0]+" - "+datetimeSplit[1];
  },

  priceFormat(value){
    let valueSplit = parseFloat(value).toFixed(2).split('.');

    valueSplit[0] = "R$ " + valueSplit[0].split(/(?=(?:...)*$)/).join('.');
    let valueString = valueSplit.join(',');

    return valueString;
  }
};