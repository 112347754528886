import React from "react";
import { Button, FormGroup, Form, Row, Col } from "reactstrap";
import AdminLayout from "../layouts/Admin/Admin.jsx";
import { withRouter } from "react-router-dom";
import { withFormik } from "formik";
import * as yup from "yup";
import {
  Api,
  MobsComponentForm,
  MobsButton,
  MobsInput,
  MobsCardCollapse,
  Utils,
} from "../mobscomponents";

const apiEndpoint = {
  detail: "Modalidade/Detail",
  update: "Modalidade/Alter",
  create: "Modalidade/Set",
};

class ModalidadesForm extends MobsComponentForm {
  constructor(props) {
    super(props);
    this.initializeEndpoints(apiEndpoint);

    this.state = {
      profiles: [
        {
          Codigo: "2",
          Descricao: "Profissional",
        },
      ],
      units: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }


  render() {
    const { values, errors, handleChange, handleSubmit } = this.props;

    return (
      <>
        <AdminLayout
          {...this.props}
          content={
            <div className="content">
              <Row>
                <Col md="12">
                  <Form onSubmit={handleSubmit}>
                    <MobsCardCollapse
                      isStatic
                      title={
                        <>
                          <MobsButton
                            className="btn-back"
                            onClick={() => this.props.history.push("/modalidades")}
                          >
                            <i className="tim-icons icon-minimal-left" />>
                          </MobsButton>
                          <h5 className="title">
                            {this.props.match.params.id
                              ? "Alterar"
                              : "Cadastrar"}{" "}
                            Modalidade
                          </h5>
                        </>
                      }
                      body={
                        <>
                          <Row>
                            <Col className="pr-md-3" md="12">
                              <FormGroup>
                                <label>Descrição</label>
                                <MobsInput
                                  type="text"
                                  name="Descricao"
                                  value={values.Descricao}
                                  placeholder="Digite a descrição"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      }
                    />

                    <Button className="btn-fill" color="primary" type="submit">
                      Salvar
                    </Button>
                  </Form>
                </Col>
              </Row>
            </div>
          }
        />
      </>
    );
  }
}

const formikForm = withFormik({
  mapPropsToValues: () => ({
    Perfil: "2",
  }),

  validateOnChange: false,

  validationSchema: yup.object().shape({
    Descricao: yup.string().required("Este campo é obrigatório")
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    setSubmitting(true);

    let endpoint = apiEndpoint.create;
    if (values.Codigo) endpoint = apiEndpoint.update;

    Api.req(endpoint, null, Utils.objToKeyVal(values))
      .then((resp) => {
        if (resp.status) {
          Utils.alertFormSuccess(resp.msg, () => {
            props.history.replace("/modalidades");
          });
        } else {
          setSubmitting(false);
          Utils.alert(resp.msg);
        }
      })
      .catch((e) => {
        setSubmitting(false);
        Utils.alert(e.message);
      });
  },
})(ModalidadesForm);

export default withRouter(formikForm);
