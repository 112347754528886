import React, { PureComponent } from "react";
import InputMask from 'react-input-mask';
import { Input, FormFeedback } from "reactstrap";



export default class MobsInput extends PureComponent {

  render() {
    let name = this.props.name;

    let errors = this.props.errors ? this.props.errors[name] : null;

    return (
      <>
        <InputMask
          mask={this.props.mask}
          name={name}
          value={this.props.value}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          disabled={this.props.disabled}
          valid={!errors}
          invalid={!!errors}
          onKeyUp={this.props.onKeyUp}
          onKeyPress={this.props.onKeyPress}
          onClick={this.props.onClick}
        >
          <Input
            placeholder={this.props.placeholder}
            defaultValue={this.props.defaultValue}
            type={this.props.type}
            inline={this.inline}
            label={this.label}
          />
        </InputMask>
        {
          errors ? <FormFeedback> {(!!errors) && errors} </FormFeedback>
            :
            <label style={{ color: "#bbbbbb", marginLeft: 10 }}> {this.props.helper} </label>
        }
      </>
    );
  }
}