import React, {Component} from "react";
import classNames from "classnames";
import { Line } from "react-chartjs-2";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Badge
} from "reactstrap";
import {
  chartTotalVendas,
  chartCancelamentosDia, 
  chartVendasDia, 
  chartEntregasDia
} from "../variables/charts.jsx";
import Api from "../mobscomponents/Api";
import AdminLayout from "../layouts/Admin/Admin.jsx";
import Utils from "../mobscomponents/Utils";

const StatusValues = [
  'pendente',
  'confirmado',
  'pago',
  'cancelado',
  'entregue'
];

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bigChartData: "pedidos",

      loadingChartCancelamentoDia: true,
      cancelamentoDiaTotal: 0,

      loadingChartEntregasDia: true,
      entregasDiaTotal: 0,

      loadingChartVendasDia: true,
      vendasDiaTotal: 0,

      lastOrders: []
    };

  }

  componentDidMount(){
    // this._loadTotalSales();
    // this._loadCanceledSales();
    // this._loadDailySales();
    // this._loadDailyDelivered();
    // this._loadOrdersTable();
  }

  _loadTotalSales(){
    Api.req('OrdersAdmin/ChartTotalSales')
      .then((e) => {
          if(e.status){
            chartTotalVendas.pedidosDataset = e.retorno.pedidos;
            chartTotalVendas.cancelamentosDataset = e.retorno.cancelamentos;
            chartTotalVendas.faturamentoDataset = e.retorno.faturamento;
            this.setBgChartData('pedidos');
          } 
          else {
            Utils.alert("Oops..", e.msg);
          }
      })
      .catch((e) => {
        Utils.alert("Oops..", "Ocorreu um problema ao buscar os dados do gráfico. Tente novamente mais tarde.");
      });
  }

  _loadCanceledSales(){
    Api.req('OrdersAdmin/ChartDailyCanceledOrders')
    .then((e) => {
        if(e.status){
          chartCancelamentosDia.dataset = e.retorno.semana;
          this.setState({loadingChartCancelamentoDia: false, cancelamentoDiaTotal: e.retorno.totalDia});
        } 
    })
    .catch((e) => {
      Utils.alert("Oops..", "Ocorreu um problema ao buscar os dados do gráfico. Tente novamente mais tarde.");
    });
  }

  _loadDailySales(){
    Api.req('OrdersAdmin/ChartDailySales')
    .then((e) => {
        if(e.status){
          chartVendasDia.datasetValue = e.retorno.semana.valor;
          chartVendasDia.datasetQty = e.retorno.semana.qtd;
          this.setState({loadingChartVendasDia: false, vendasDiaTotal: e.retorno.totalDia});
        } 
    })
    .catch((e) => {
      Utils.alert("Oops..", "Ocorreu um problema ao buscar os dados do gráfico. Tente novamente mais tarde.");
    });
  }

  _loadDailyDelivered(){
    Api.req('OrdersAdmin/ChartDailyDelivered')
    .then((e) => {
        if(e.status){
          chartEntregasDia.dataset = e.retorno.semana;
          this.setState({loadingChartEntregasDia: false, entregasDiaTotal: e.retorno.totalDia});
        } 
    })
    .catch((e) => {
      Utils.alert("Oops..", "Ocorreu um problema ao buscar os dados do gráfico. Tente novamente mais tarde.");
    });
  }

  _loadOrdersTable(){        
    let customData = [
      {key: 'OrderStatus', value: JSON.stringify(StatusValues)},
      {key: 'Limit', value: 10},
      {key: 'Order', value: 'dataInsert DESC'}
    ];

    Api.req('OrdersAdmin/Get', null, customData)
      .then((e) => {
        if(e.status){
          this.setState({lastOrders: e.retorno});
        }
        else {
          Utils.alert("Oops..", e.msg);
        }
      })
      .catch((e) => {
        Utils.alert("Oops..", "Ocorreu um problema ao buscar os dados do gráfico. Tente novamente mais tarde.");
      });
  }

  setBgChartData = name => {
    this.setState({
      bigChartData: name
    });
  };

  mapBadgeStatus(status, fontsize){
    let badge = <Badge pill color="info" style={{fontSize: fontsize}}>{status.toUpperCase()}</Badge>;

    switch(status){
        case 'pendente':
            badge = <Badge pill color="warning" style={{fontSize: fontsize}}>{status.toUpperCase()}</Badge>
            break;
        case 'confirmado':
            badge = <Badge pill color="info" style={{fontSize: fontsize}}>{status.toUpperCase()}</Badge>
            break;
        case 'pago':
            badge = <Badge pill color="success" style={{fontSize: fontsize}}>{status.toUpperCase()}</Badge>
            break;    
    }

    return badge;
  }

  render() {
    return (
      <>
        <AdminLayout
            {...this.props}
          content={
        <div className="content">
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <h5 className="card-category">Total de Vendas</h5>
                      <CardTitle tag="h2">Desempenho</CardTitle>
                    </Col>
                    <Col sm="6">
                      <ButtonGroup
                        className="btn-group-toggle float-right"
                        data-toggle="buttons"
                      >
                        <Button
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "pedidos"
                          })}
                          color="info"
                          id="0"
                          size="sm"
                          onClick={() => this.setBgChartData("pedidos")}
                        >
                          <input
                            defaultChecked
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Pedidos
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="1"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "cancelamentos"
                          })}
                          onClick={() => this.setBgChartData("cancelamentos")}
                        >
                          <input
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Cancelamentos
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="2"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: this.state.bigChartData === "faturamento"
                          })}
                          onClick={() => this.setBgChartData("faturamento")}
                        >
                          <input
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Faturamento
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={chartTotalVendas[this.state.bigChartData]}
                      options={chartTotalVendas.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div> }
          />
      </>
    );
  }
}

export default Dashboard;
