import React, {Component} from "react";
import {Col, Row} from "reactstrap";
import AdminLayout from "../layouts/Admin/Admin.jsx";
import {
    Api,
    MobsButton,
    MobsCardCollapse,
    MobsTable,
    Utils,
} from "../mobscomponents";

class Horses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: [],
            tableMsg: "Carregando...",
        };

        this.bootstrapTableRef = React.createRef();
    }

    componentDidMount() {
        Api.req("Horses/List/filter?status=all")
            .then((e) => {
                if (e.status) {
                    this.setState({
                        dataset: e.retorno,
                        tableMsg: "Não há dados na tabela",
                    });
                } else {
                    Utils.alert("Oops..", e.msg);
                    this.setState({tableMsg: "Não há dados na tabela"});
                }
            })
            .catch((e) => {
                Utils.alert("Oops..", e.toString());
                this.setState({tableMsg: "Não há dados na tabela"});
            });
    }

    handleDeleteButtonClick = (value, row) => {
        Utils.alertConfirm(
            "Atenção",
            `Confirmar exclusão do cavalo '${row.NomeCompleto}'?`,
            "warning",
            "Confirmar",
            "Não",
            (confirm) => {
                if (confirm) {
                    Utils.loading();
                    Api.req("Horses/Remove", null, [{key: "Codigo", value: value}])
                        .then((e) => {
                            Utils.cancelLoading();
                            if (e.status) {
                                let dataset = this.state.dataset.filter(
                                    (u) => u.Codigo !== value
                                );
                                this.setState({dataset});
                                Utils.alert(`O cavalo '${row.NomeCompleto}' foi excluído.`, e.msg, "success");
                            } else {
                                Utils.alert("Oops..", e.msg);
                            }
                        })
                        .catch((e) => {
                            Utils.cancelLoading();
                            Utils.alert("Oops..", e.toString());
                        });
                }
            }
        );
    }

    handleActiveButtonClick = (value, row) => {
        let title = row.Active == 1 ? `Desativar publicação` : `Reativar publicação`
        let message = row.Active == 1
            ? `Você confirma que quer DESATIVAR a publicação do cavalo '${row.NomeCompleto}'?`
            : `Você confirma que quer REATIVAR a publicação do cavalo '${row.NomeCompleto}'?`
        let type = row.Active == 1 ? 'warning' : 'success'
        let newStatus = row.Active == 1 ? 0 : 1;
        let newStatusStr = row.Active == 1 ? 'Inativo' : 'Ativo';

        let feedback = row.Active == 1
            ? `A publicação do cavalo '${row.NomeCompleto}' foi desativada.`
            : `A publicação do cavalo '${row.NomeCompleto}' foi reativada.`

        Utils.alertConfirm(
            title,
            message,
            type,
            "Confirmar",
            "Não",
            (confirm) => {
                if (confirm) {
                    Utils.loading();
                    Api.req("Horses/ChangeStatus", null, [
                        {key: "Codigo", value: value},
                        {key: "Active", value: newStatus}
                    ])
                        .then((e) => {
                            Utils.cancelLoading();
                            if (e.status) {
                                let dataset = this.state.dataset.map(
                                    (horse) => {
                                        if (horse.Codigo === value) {
                                            return {
                                                ...horse,
                                                "Active": newStatus,
                                                "Status": newStatusStr,
                                            }
                                        }

                                        return horse;
                                    }
                                );
                                this.setState({dataset});
                                Utils.alert(feedback, e.msg, "success");
                            } else {
                                Utils.alert("Oops..", e.msg);
                            }
                        })
                        .catch((e) => {
                            Utils.cancelLoading();
                            Utils.alert("Oops..", e.toString());
                        });
                }
            }
        );
    }

    createButton = (value, row) => {
        return (
            <div>
                <MobsButton
                    tooltip={"Desativar Cavalo"}
                    path={`/horses-formulario/${value}`}
                    color={"info"}
                    size={"sm"}
                >
                    <i className="tim-icons icon-pencil"/>
                </MobsButton>
                <MobsButton
                    tooltip={"Excluir Cavalo"}
                    onClick={() => this.handleDeleteButtonClick(value, row)}
                    color={"danger"}
                    size={"sm"}
                >
                    <i className="tim-icons icon-trash-simple"/>
                </MobsButton>
                <MobsButton
                    tooltip={row.Active == 1 ? "Desativar Cavalo" : "Ativar Cavalo"}
                    onClick={() => this.handleActiveButtonClick(value, row)}
                    color={row.Active == 1 ? "danger" : "success"}
                    size={"sm"}
                >
                    <i className="tim-icons icon-button-power"/>
                </MobsButton>
            </div>
        );
    };

    render() {
        const optionsPage = {
            hideSizePerPage: true,
            noDataText: this.state.tableMsg,
            beforeShowError: (type, msg) => {
                this.bootstrapTableRef.current.body.cancelEditCell();

                if (msg === false) return;

                Utils.alert("Atenção!", msg, "error");
            },
        };

        return (
            <>
                <AdminLayout
                    {...this.props}
                    content={
                        <div className="content">
                            <Row>
                                <Col md="12">
                                    <MobsCardCollapse
                                        isStatic
                                        title={
                                            <>
                                                Meus cavalos cadastrados
                                                <MobsButton
                                                    className="btn-simple float-right"
                                                    path={`/horses-formulario`}
                                                    color={"info"}
                                                    size={"sm"}
                                                >
                                                    Cadastrar
                                                </MobsButton>
                                            </>
                                        }
                                        body={
                                            <>
                                                <MobsTable
                                                    pagination
                                                    options={optionsPage}
                                                    refBootstrapTable={this.bootstrapTableRef}
                                                    dataSet={this.state.dataset}
                                                    tableFields={[
                                                        {
                                                            key: "Codigo",
                                                            label: "Código",
                                                            placeholder: "Pesquisar Código",
                                                            dataSort: true,
                                                            isKey: true,
                                                        },
                                                        {
                                                            key: "NomeCompleto",
                                                            label: "Nome Completo",
                                                            placeholder: "Pesquisar Nome Completo",
                                                            dataSort: true,
                                                        },
                                                        {
                                                            key: "Status",
                                                            label: "Status",
                                                            placeholder: "Pesquisar status",
                                                            dataSort: true,
                                                        },
                                                        {
                                                            key: "Link",
                                                            label: "Link",
                                                            placeholder: "Pesquisar Link",
                                                            dataSort: true,
                                                        },
                                                        {
                                                            key: "Codigo",
                                                            label: "#",
                                                            type: "buttons",
                                                            dataAlign: "right",
                                                            createButtons: this.createButton.bind(this),
                                                        },
                                                    ]}
                                                    optionsPage={optionsPage}
                                                />
                                            </>
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                />
            </>
        );
    }
}

export default Horses;
