import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { withRouter } from 'react-router-dom';
import { withFormik } from "formik";
import * as yup from 'yup';
import {
  Api,
  MobsComponentForm,
  MobsInput,
  Utils,
  Prefs
} from "../mobscomponents";
import "../assets/demo/login.scss";
import logo from "../assets/img/card-login.png";

class Login extends MobsComponentForm {

  componentDidMount(){
    if(Prefs.get("token") != null)
      this.props.history.replace('/dashboard');
  }

  render() {
    const {
      values,
      errors,
      handleChange,
      handleSubmit,
      isSubmitting
    } = this.props;

    return (
      <>
        <div className="wrapper wrapper-full-page">
          <div
            className="full-page login-page"
            ref="mainPanel"
          >
            <div className="content">
              <div className="container">
                <Col className="ml-auto mr-auto" md="6" lg="4">
                  <Form onSubmit={handleSubmit}>
                    <Card className="card card-login card-white">
                      <CardHeader>
                        <img src={logo} alt="react-logo" />
                        <h1 className="card-title text-white">acesso</h1>
                      </CardHeader>
                      <CardBody>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <MobsInput
                            placeholder="E-mail do usuário"
                            name="Email"
                            value={values.Email}
                            onChange={handleChange}
                            errors={errors}
                          />
                        </InputGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <MobsInput
                            placeholder="Senha"
                            type="password"
                            name="Senha"
                            value={values.Senha}
                            onChange={handleChange}
                            errors={errors}
                          />
                        </InputGroup>
                      </CardBody>
                      <CardFooter>
                        <Button className="mb-3 btn-primary btn-lg btn-block" color="primary" disabled={isSubmitting}
                          type="submit">
                          Acessar
                        </Button>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const formikForm = withFormik({
  mapPropsToValues: () => ({}),

  validateOnChange: false,
  
  validationSchema: yup.object().shape({
    Email: yup.string().email("E-mail inválido").required("O campo e-mail é obrigatório"),
    Senha: yup.string().required("O campo senha é obrigatório")
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    setSubmitting(true);

    Api.req('Auth/Login', null, Utils.objToKeyVal(values))
      .then((e) => {
        if (e.status) {
          Prefs.set("token", e.retorno);
          props.history.push('/dashboard');
        } else {
          setSubmitting(false);
          Utils.alert("Oops..", e.msg, 'warning');
        }
      })
      .catch((e) => {
        setSubmitting(false);
        Utils.alert("Oops..", e.toString());
      });
  },
})(Login);

export default withRouter(formikForm);
