import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class MobsModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    return (
      <div>
        <Button {...this.props.openButton} onClick={this.toggle}>{this.props.openButtonChildren}</Button>
        <Modal 
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            centered={this.props.centered}
            role={this.props.role}
            size={this.props.size}
            backdrop={this.props.backdrop}
            scrollable={this.props.scrollable}
        >
          <ModalHeader toggle={this.toggle}>
            {this.props.headerContent}
          </ModalHeader>

          <ModalBody>
            {this.props.children}
          </ModalBody>
          
          <ModalFooter>
            {this.props.footerContent}
            {this.props.saveButton && <Button color="primary" onClick={() => {
                this.props.saveButton.onClickSave()
                this.toggle()
              }}>Salvar</Button>
            }
            {
              this.props.dismissButton && <Button color="secondary" onClick={this.toggle}>Fechar</Button>
            }
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default MobsModal;