import {withFormik} from "formik";
import React from "react";
import {withRouter} from "react-router-dom";
import {Button, Col, Form, FormGroup, Row} from "reactstrap";
import * as yup from "yup";
import AdminLayout from "../layouts/Admin/Admin.jsx";
import {Select} from 'antd';
import {
  Api,
  MobsButton,
  MobsCardCollapse,
  MobsComponentForm,
  MobsDropzone,
  MobsInput,
  MobsInputSelect,
  Utils,
} from "../mobscomponents";
import video from "../assets/img/video-upload.png";
import audio from "../assets/img/audio-upload.png";

const apiEndpoint = {
  detail: "Horses/Detail",
  update: "Horses/Alter",
  create: "Horses/Set",
};
const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  borderColor: '#00f2c3',
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};
const thumbInner = {
  position: 'relative',
  display: 'flex',
  minWidth: '100%',
  overflow: 'hidden'
};
const img = {
  display: 'block',
  width: '100%',
  height: '100%'
};
const imgCancel = {
  display: 'flex',
  flexDirection: 'row',
  right: 0,
  position: 'absolute',
  color: 'red'
};

class HorsesForm extends MobsComponentForm {
  constructor(props) {
    super(props);

    this.initializeEndpoints(apiEndpoint);
    this.state = {
      modalidades: [],
      racas: [],
      parentes: [],
    };
  }

  componentDidMount() {
    this.loadModalidades();
    this.loadRacas();
    this.loadParentes();
    super.componentDidMount();
  }

  loadModalidades() {
    Utils.loading();

    Api.req("Modalidade/Get", null)
      .then((e) => {
        Utils.cancelLoading();
        if (e.status) {
          this.setState({modalidades: e.retorno});
        } else {
          Utils.alert("Oops..", e.msg);
        }
      })
      .catch((e) => {
        Utils.cancelLoading();
        Utils.alert("Oops..", e.toString());
      });
  }

  loadRacas() {
    Utils.loading();

    Api.req("Racas/Get", null)
      .then((e) => {
        Utils.cancelLoading();
        if (e.status) {
          this.setState({racas: e.retorno});
        } else {
          Utils.alert("Oops..", e.msg);
        }
      })
      .catch((e) => {
        Utils.cancelLoading();
        Utils.alert("Oops..", e.toString());
      });
  }

  loadParentes() {
    Utils.loading();

    Api.req("Horses/Get", null)
      .then((e) => {
        Utils.cancelLoading();
        if (e.status) {
          this.setState({parentes: e.retorno});
        } else {
          Utils.alert("Oops..", e.msg);
        }
      })
      .catch((e) => {
        Utils.cancelLoading();
        Utils.alert("Oops..", e.toString());
      });
  }

  renderEstados() {
    return <>
      <option value="AC">Acre</option>
      <option value="AL">Alagoas</option>
      <option value="AP">Amapá</option>
      <option value="AM">Amazonas</option>
      <option value="BA">Bahia</option>
      <option value="CE">Ceará</option>
      <option value="DF">Distrito Federal</option>
      <option value="ES">Espírito Santo</option>
      <option value="GO">Goías</option>
      <option value="MA">Maranhão</option>
      <option value="MT">Mato Grosso</option>
      <option value="MS">Mato Grosso do Sul</option>
      <option value="MG">Minas Gerais</option>
      <option value="PA">Pará</option>
      <option value="PB">Paraíba</option>
      <option value="PR">Paraná</option>
      <option value="PE">Pernambuco</option>
      <option value="PI">Piauí</option>
      <option value="RJ">Rio de Janeiro</option>
      <option value="RN">Rio Grande do Norte</option>
      <option value="RS">Rio Grande do Sul</option>
      <option value="RO">Rondônia</option>
      <option value="RR">Roraíma</option>
      <option value="SC">Santa Catarina</option>
      <option value="SP">São Paulo</option>
      <option value="SE">Sergipe</option>
      <option value="TO">Tocantins</option>
    </>
  }

  render() {
    const {
      values,
      errors,
      handleChange,
      handleSubmit,
      setFieldValue,
    } = this.props;

    return (
      <>
        <AdminLayout
          {...this.props}
          content={
            <div className="content">
              <Row>
                <Col md="12">
                  <Form onSubmit={handleSubmit}>
                    <MobsCardCollapse
                      isStatic
                      title={
                        <>
                          <MobsButton
                            className="btn-back"
                            onClick={() => this.props.history.push("/horses")}
                          >
                            <i className="tim-icons icon-minimal-left"/>>
                          </MobsButton>
                          <h5 className="title">
                            {this.props.match.params.id
                              ? "Alterar"
                              : "Cadastrar"}{" "}
                            Horses
                          </h5>
                        </>
                      }
                      body={
                        <>
                          <Row>
                            <Col className="pr-md-1" md="1">
                              <FormGroup>
                                <label>Ativo ?</label>
                                <MobsInputSelect
                                    name={"Active"}
                                    placeholder={"Active"}
                                    value={values.Active}
                                    onChange={handleChange}
                                    errors={errors}
                                >
                                  <option value="0">
                                    Não
                                  </option>
                                  <option value="1">
                                    Sim
                                  </option>
                                </MobsInputSelect>
                              </FormGroup>
                            </Col>
                          </Row><Row>
                          <Col className="pr-md-4" md="4">
                            <FormGroup>
                              <label>Nome Completo</label>
                              <MobsInput
                                  type="text"
                                  name="NomeCompleto"
                                  value={values.NomeCompleto}
                                  placeholder="Digite o nome completo"
                                  onChange={handleChange}
                                  errors={errors}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pr-md-4" md="2">
                            <FormGroup>
                              <label>Código Externo</label>
                              <MobsInput
                                  type="text"
                                  name="CodigoExterno"
                                  value={values.CodigoExterno}
                                  placeholder="Digite a codigo externo"
                                  onChange={handleChange}
                                  errors={errors}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pr-md-2" md="2">
                            <FormGroup>
                              <label>Pedigree</label>
                              <MobsInput
                                  type="text"
                                  name="Pedigree"
                                  value={values.Pedigree}
                                  placeholder="Digite a pedigree"
                                  onChange={handleChange}
                                  errors={errors}
                              />
                            </FormGroup>
                          </Col>

                          <Col className="pr-md-3" md="3">
                            <FormGroup>
                              <label>Link</label>
                              <MobsInput
                                  type="text"
                                  name="Link"
                                  value={values.Link}
                                  placeholder="Digite o link"
                                  onChange={handleChange}
                                  errors={errors}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pr-md-1" md="1">
                            <FormGroup>
                              <label>Destaque</label>
                              <MobsInputSelect
                                  name={"Destaque"}
                                  placeholder={"Destaque"}
                                  value={values.Destaque}
                                  onChange={handleChange}
                                  errors={errors}
                              >
                                <option value="N">
                                  Não
                                </option>
                                <option value="S">
                                  Sim
                                </option>
                              </MobsInputSelect>
                            </FormGroup>
                          </Col>
                        </Row>
                          <Row>

                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Cor</label>
                                <MobsInput
                                  type="text"
                                  name="Cor"
                                  value={values.Cor}
                                  placeholder="Digite a Cor"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Idade</label>
                                <MobsInput
                                  type="text"
                                  name="Idade"
                                  value={values.Idade}
                                  placeholder="Digite a Idade"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Modalidades</label>
                                <Select
                                  allowClear
                                  name={"Modalidades"}
                                  value={values.Modalidades}
                                  mode="multiple"
                                  onChange={(v) => setFieldValue("Modalidades", v)}
                                  optionLabelProp="label"
                                  errors={errors}
                                >
                                  {this.state.modalidades.map((e) => (
                                    <Select.Option key={e.Codigo} value={e.Codigo} label={e.Descricao}>
                                      <div className="demo-option-label-item">
                                        <span role="img" aria-label="Korea">
                                          {e.Descricao}
                                        </span>
                                      </div>
                                    </Select.Option>
                                  ))}
                                </Select>
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Raça</label>
                                <MobsInputSelect
                                  name={"Raca"}
                                  placeholder={"Raca"}
                                  value={values.Raca}
                                  onChange={handleChange}
                                  errors={errors}
                                >
                                  <option value="">Selecione a raça</option>
                                  {this.state.racas.map((e) => (
                                    <option value={e.Codigo}>
                                      {e.Descricao}
                                    </option>
                                  ))}
                                </MobsInputSelect>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Proprietario</label>

                                <MobsInput
                                  type="text"
                                  name="Proprietario"
                                  value={values.Proprietario}
                                  placeholder="Digite o proprietario"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Contato do Proprietario</label>
                                <MobsInput
                                  type="text"
                                  name="ContatoProprietario"
                                  value={values.ContatoProprietario}
                                  placeholder="Digite o Contato do proprietario"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Cidade</label>
                                <MobsInput
                                  type="text"
                                  name="Cidade"
                                  value={values.Cidade}
                                  placeholder="Digite a Cidade"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Estado</label>
                                <MobsInputSelect
                                  name={"Estado"}
                                  placeholder={"Estado"}
                                  value={values.Estado}
                                  onChange={handleChange}
                                  errors={errors}
                                >
                                  {this.renderEstados()}
                                </MobsInputSelect>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Alojado</label>
                                <MobsInput
                                  type="text"
                                  name="Alojado"
                                  value={values.Alojado}
                                  placeholder="Digite o alojado"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Contato do Alojado</label>
                                <MobsInput
                                  type="text"
                                  name="ContatoAlojado"
                                  value={values.ContatoAlojado}
                                  placeholder="Digite o Contato do alojado"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Cidade Alojado</label>
                                <MobsInput
                                  type="text"
                                  name="CidadeAlojado"
                                  value={values.CidadeAlojado}
                                  placeholder="Digite a Cidade do Alojado"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Estado do Alojado</label>
                                <MobsInputSelect
                                  name={"EstadoAlojado"}
                                  placeholder={"Estado do Alojado"}
                                  value={values.EstadoAlojado}
                                  onChange={handleChange}
                                  errors={errors}
                                >
                                  {this.renderEstados()}
                                </MobsInputSelect>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Local do Semen1</label>
                                <MobsInput
                                  type="text"
                                  name="LocalSemen1"
                                  value={values.LocalSemen1}
                                  placeholder="Digite o Local do Semen1"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Contato do Semen1</label>
                                <MobsInput
                                  type="text"
                                  name="ContatoSemen1"
                                  value={values.ContatoSemen1}
                                  placeholder="Digite o Contato do Semen1"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Cidade Semen1</label>
                                <MobsInput
                                  type="text"
                                  name="CidadeSemen1"
                                  value={values.CidadeSemen1}
                                  placeholder="Digite a Cidade do Semen1"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Estado do Semen1</label>
                                <MobsInputSelect
                                  name={"EstadoSemen1"}
                                  placeholder={"Estado do Semen1"}
                                  value={values.EstadoSemen1}
                                  onChange={handleChange}
                                  errors={errors}
                                >
                                  {this.renderEstados()}
                                </MobsInputSelect>
                              </FormGroup>
                            </Col>
                          </Row>


                          <Row>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Local do Semen2</label>
                                <MobsInput
                                  type="text"
                                  name="LocalSemen2"
                                  value={values.LocalSemen2}
                                  placeholder="Digite o Local Semen2"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Contato do Semen2</label>
                                <MobsInput
                                  type="text"
                                  name="ContatoSemen2"
                                  value={values.ContatoSemen2}
                                  placeholder="Digite o Contato do Semen2"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Cidade Semen2</label>
                                <MobsInput
                                  type="text"
                                  name="CidadeSemen2"
                                  value={values.CidadeSemen2}
                                  placeholder="Digite a Cidade do Semen2"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Estado do Semen2</label>
                                <MobsInputSelect
                                  name={"EstadoSemen2"}
                                  placeholder={"Estado do Semen2"}
                                  value={values.EstadoSemen2}
                                  onChange={handleChange}
                                  errors={errors}
                                >
                                  {this.renderEstados()}
                                </MobsInputSelect>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Veterinario</label>
                                <MobsInput
                                  type="text"
                                  name="Veterinario"
                                  value={values.Veterinario}
                                  placeholder="Digite o Veterinario"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="3">
                              <FormGroup>
                                <label>Contato do Veterinario</label>
                                <MobsInput
                                  type="text"
                                  name="ContatoVeterinario"
                                  value={values.ContatoVeterinario}
                                  placeholder="Digite o Contato do Veterinario"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-md-3" md="7">
                              <FormGroup>
                                <label>Descrição</label>
                                <MobsInput
                                  type="textarea"
                                  name="Descricao"
                                  value={values.Descricao}
                                  placeholder="Digite a descrição"
                                  onChange={handleChange}
                                  errors={errors}
                                  style={{height: 500, maxHeight: 500}}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="5">
                              <FormGroup>
                                <label>Cobertura</label>
                                <MobsInput
                                  type="textarea"
                                  name="Cobertura"
                                  value={values.Cobertura}
                                  placeholder="Digite a cobertura"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>

                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-md-3" md="12">
                              <FormGroup>
                                <label>Imagem</label>
                                <MobsDropzone
                                  name={`imagem`}
                                  maxSize={700000000}
                                  accept="image/jpeg, image/png"
                                  maxFiles={5}
                                  maxFilesMessage="É permitido subir apenas 5 imagem"
                                  value={values.imagem}
                                  preview={values.imagem_preview}
                                  previewName={"imagem_preview"}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                >
                                  <p>Arraste ou Clique para fazer upload da imagem</p>
                                </MobsDropzone>
                              </FormGroup>
                            </Col>
                          </Row>
                          {/*<Row>*/}
                          {/*  <Col className="pr-md-3" md="12">*/}
                          {/*    <FormGroup>*/}
                          {/*      {values.imagem.map((f) => (*/}
                          {/*        <div style={thumb}>*/}
                          {/*          <div style={thumbInner}>*/}
                          {/*            {(f.type !== 'image/jpeg' || f.type !== 'image/png') && f.type === 'video/mp4' ?*/}
                          {/*              <img*/}
                          {/*                src={video}*/}
                          {/*                style={img}*/}
                          {/*                alt=''*/}
                          {/*              />*/}
                          {/*              : (f.type !== 'image/jpeg' || f.type !== 'image/png') && f.type === 'audio/mp3' ?*/}
                          {/*                <img*/}
                          {/*                  src={audio}*/}
                          {/*                  style={img}*/}
                          {/*                  alt=''*/}
                          {/*                />*/}
                          {/*                :*/}
                          {/*                <img*/}
                          {/*                  src={f.preview}*/}
                          {/*                  style={img}*/}
                          {/*                  alt=''*/}
                          {/*                />*/}
                          {/*            }*/}
                          {/*            <Button color="danger" close style={imgCancel} aria-label="Excluir" onClick={*/}
                          {/*              () => {*/}
                          {/*                let valueFiltered = values.imagem.filter(u => u !== f);*/}
                          {/*                if (valueFiltered.length === 0) {*/}
                          {/*                  this.setFieldValue(`imagem`, null);*/}
                          {/*                } else {*/}
                          {/*                  this.setFieldValue(`imagem`, valueFiltered);*/}
                          {/*                }*/}
                          {/*              }*/}
                          {/*            }*/}
                          {/*            />*/}
                          {/*          </div>*/}
                          {/*        </div>*/}
                          {/*      ))}*/}
                          {/*    </FormGroup>*/}
                          {/*  </Col>*/}
                          {/*</Row>*/}
                        </>
                      }
                    />
                    <MobsCardCollapse
                      isStatic
                      title={
                        <>
                          <h5 className="title">Pedigree Linha Alta</h5>
                        </>
                      }
                      body={
                        <>

                          <Row>
                            <Col className="pr-md-3" md="4">
                              <FormGroup>
                                <label>Pai</label>

                                <MobsInput
                                  type="text"
                                  name="Pai"
                                  value={values.Pai}
                                  placeholder="Digite o Pai"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="8">
                              <Row>
                                <Col className="pr-md-3" md="6">
                                  <FormGroup>
                                    <label>Avô Paterno</label>

                                    <MobsInput
                                      type="text"
                                      name="GrandfatherPai"
                                      value={values.GrandfatherPai}
                                      placeholder="Digite o Avô Paterno"
                                      onChange={handleChange}
                                      errors={errors}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="pr-md-3" md="6">
                                  <Row>
                                    <Col className="pr-md-3" md="12">
                                      <FormGroup>
                                        <label>Pai do Avô Paterno</label>
                                        <MobsInput
                                          type="text"
                                          name="GrandfatherGrandfatherPai"
                                          value={values.GrandfatherGrandfatherPai}
                                          placeholder="Digite o Pai do Avô Paterno"
                                          onChange={handleChange}
                                          errors={errors}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col className="pr-md-3" md="12">
                                      <FormGroup>
                                        <label>Mãe do Avô Paterno</label>
                                        <MobsInput
                                          type="text"
                                          name="GrandmotherGrandfatherPai"
                                          value={values.GrandmotherGrandfatherPai}
                                          placeholder="Digite a Mãe do Avô Paterno"
                                          onChange={handleChange}
                                          errors={errors}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="pr-md-3" md="6">
                                  <FormGroup>
                                    <label>Avó Paterna</label>

                                    <MobsInput
                                      type="text"
                                      name="GrandmotherPai"
                                      value={values.GrandmotherPai}
                                      placeholder="Digite a Avó Paterna"
                                      onChange={handleChange}
                                      errors={errors}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="pr-md-3" md="6">
                                  <Row>
                                    <Col className="pr-md-3" md="12">
                                      <FormGroup>
                                        <label>Pai da Avó Paterna</label>

                                        <MobsInput
                                          type="text"
                                          name="GrandfatherGrandmotherPai"
                                          value={values.GrandfatherGrandmotherPai}
                                          placeholder="Digite o Pai da Avó Paterna"
                                          onChange={handleChange}
                                          errors={errors}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col className="pr-md-3" md="12">
                                      <FormGroup>
                                        <label>Mãe da Avó Paterna</label>
                                        <MobsInput
                                          type="text"
                                          name="GrandmotherGrandmotherPai"
                                          value={values.GrandmotherGrandmotherPai}
                                          placeholder="Digite a Mãe da Avó Paterna"
                                          onChange={handleChange}
                                          errors={errors}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      }
                    />


                    <MobsCardCollapse
                      isStatic
                      title={
                        <>
                          <h5 className="title">Pedigree Linha Baixa</h5>
                        </>
                      }
                      body={
                        <>

                          <Row>
                            <Col className="pr-md-3" md="4">
                              <FormGroup>
                                <label>Mãe</label>

                                <MobsInput
                                  type="text"
                                  name="Mae"
                                  value={values.Mae}
                                  placeholder="Digite a Mãe"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="8">
                              <Row>
                                <Col className="pr-md-3" md="6">
                                  <FormGroup>
                                    <label>Avô Materno</label>
                                    <MobsInput
                                      type="text"
                                      name="GrandfatherMae"
                                      value={values.GrandfatherMae}
                                      placeholder="Digite o Avô Materno"
                                      onChange={handleChange}
                                      errors={errors}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="pr-md-3" md="6">
                                  <Row>
                                    <Col className="pr-md-3" md="12">
                                      <FormGroup>
                                        <label>Pai do Avô Materno</label>
                                        <MobsInput
                                          type="text"
                                          name="GrandfatherGrandfatherMae"
                                          value={values.GrandfatherGrandfatherMae}
                                          placeholder="Digite o Pai do Avô Materno"
                                          onChange={handleChange}
                                          errors={errors}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col className="pr-md-3" md="12">
                                      <FormGroup>
                                        <label>Mãe do Avô Materno</label>
                                        <MobsInput
                                          type="text"
                                          name="GrandmotherGrandfatherMae"
                                          value={values.GrandmotherGrandfatherMae}
                                          placeholder="Digite a Mãe do Avô Materno"
                                          onChange={handleChange}
                                          errors={errors}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="pr-md-3" md="6">
                                  <FormGroup>
                                    <label>Avó Materna</label>
                                    <MobsInput
                                      type="text"
                                      name="GrandmotherMae"
                                      value={values.GrandmotherMae}
                                      placeholder="Digite a Avó Materno"
                                      onChange={handleChange}
                                      errors={errors}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col className="pr-md-3" md="6">
                                  <Row>
                                    <Col className="pr-md-3" md="12">
                                      <FormGroup>
                                        <label>Pai da Avó Materna</label>
                                        <MobsInput
                                          type="text"
                                          name="GrandfatherGrandmotherMae"
                                          value={values.GrandfatherGrandmotherMae}
                                          placeholder="Digite o Pai da Avó Materna"
                                          onChange={handleChange}
                                          errors={errors}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col className="pr-md-3" md="12">
                                      <FormGroup>
                                        <label>Mãe da Avó Materna</label>

                                        <MobsInput
                                          type="text"
                                          name="GrandmotherGrandmotherMae"
                                          value={values.GrandmotherGrandmotherMae}
                                          placeholder="Digite a Mãe da Avó Materna"
                                          onChange={handleChange}
                                          errors={errors}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      }
                    />

                    <Button className="btn-fill" color="primary" type="submit">
                      Salvar
                    </Button>
                  </Form>
                </Col>
              </Row>
            </div>
          }
        />
      </>
    );
  }
}

const formikForm = withFormik({
  mapPropsToValues: () => ({Modalidades: []}),

  validateOnChange: false,

  validationSchema: yup.object().shape({
    NomeCompleto: yup.string().required("Este campo é obrigatório"),
    // CodigoExterno: yup.string().required("Este campo é obrigatório"),
    // Cobertura: yup.string().required("Este campo é obrigatório"),
    // Pedigree: yup.string().required("Este campo é obrigatório"),
    Proprietario: yup.string().required("Este campo é obrigatório"),
    Destaque: yup.string().required("Este campo é obrigatório"),
    // Ativo: yup.string().required("Este campo é obrigatório"),
    ContatoProprietario: yup.string().required("Este campo é obrigatório"),
    Alojado: yup.string().required("Este campo é obrigatório"),
    ContatoAlojado: yup.string().required("Este campo é obrigatório"),
    Descricao: yup.string().required("Este campo é obrigatório"),
    Link: yup.string().required("Este campo é obrigatório"),
    Modalidades: yup.string().required("Este campo é obrigatório"),
    Raca: yup.string().required("Este campo é obrigatório"),
    Pai: yup.string().required("Este campo é obrigatório"),
    Mae: yup.string().required("Este campo é obrigatório"),
    imagem: yup.mixed().when(
      'content_file_preview', {
        is: (val) => val === undefined,
        then: yup.mixed().required("Este campo é obrigatório")
      }),
  }),

  handleSubmit: (values, {setSubmitting, props}) => {
    setSubmitting(true);

    let newValues = Utils.copyObjectWithoutReferencing(values);

    let endpoint = apiEndpoint.create;
    if (values.Codigo) endpoint = apiEndpoint.update;
    newValues.old_images = newValues.imagem.filter(img => img.id).map(img => img.id)
    newValues.new_images = newValues.imagem.filter(img => img.id === undefined)

    Utils.loading();
    Api.req(endpoint, null, Utils.objToKeyVal(newValues))
      .then((resp) => {
        if (resp.status) {
          Utils.cancelLoading();
          Utils.alertFormSuccess(resp.msg, () => {
            props.history.replace("/horses");
          });
        } else {
          setSubmitting(false);
          Utils.alert(resp.msg);
        }
      })
      .catch((e) => {
        Utils.cancelLoading();
        setSubmitting(false);
        Utils.alert(e.message);
      });
  },
})(HorsesForm);

export default withRouter(formikForm);
