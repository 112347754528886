/* eslint-disable */
import Swal from 'sweetalert2';
import moment from 'moment';
import momentDuration from 'moment-duration-format';

class Utils {
  contains(a, b) {
    return this.normalizeText(a).includes(this.normalizeText(b));
  }

  removeAllButNumbers(s) {
    if (!s) {
      return "";
    }

    return s.replace(/[^0-9]/g, '');
  }

  datetimeFormatStrToBR(date) {
    if (!date)
      return;

    return moment(date).format("DD/MM/YYYY - HH:mm:ss");
  }

  dateFormatStrToBR(date) {
    if (!date)
      return;

    return moment(date).format("DD/MM/YYYY");
  }

  priceFormat(value) {
    let valueSplit = parseFloat(value).toFixed(2).split('.');

    valueSplit[0] = "R$ " + valueSplit[0].split(/(?=(?:...)*$)/).join('.');
    let valueString = valueSplit.join(',');

    return valueString;
  }

  objToKeyVal(obj) {
    let returnObj = [];
    let keyValObj = Object.entries(obj).map(([key, value]) => ({ key, value }))

    keyValObj = keyValObj.filter(v => {
      if (v.value instanceof Array && v.value.length > 0) {
        returnObj = [...returnObj, ...this.arrayToKeyVal(`${v.key}[]`, v.value)];
        return false;
      }
      return v;
    });

    return [...returnObj, ...keyValObj];
  }

  arrayToKeyVal(key, array) {
    return array.map((el) => ({ key: key, value: el }));
  }

  alert(title = "Atenção", message = "", type = 'error') {
    Swal.fire(title, message, type);
  }

  alertConfirm(title = "Tem Certeza?", message = "Você quer mesmo realizar esta ação?", type = 'warning', confirmButtonText = "Sim", cancelButtonText = "Não", callback) {
    Swal.fire({
      title: title,
      text: message,
      type: type,
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText
    }).then((result) => {
      if (result.value) {
        callback(true);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        callback(false);
      }
    })
  }

  alertFormSuccess(msg, onCloseCallback) {
    Swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      allowEnterKey: false,
      title: 'Salvo com sucesso!',
      text: msg,
      type: 'success',
      timer: 1000,
      showCloseButton: false,
      showCancelButton: false,
      onBeforeOpen: () => {
        this.loading();
      },
      onClose: () => onCloseCallback && onCloseCallback()
    });
  }

  loading() {
    Swal.showLoading();
  }

  cancelLoading() {
    Swal.close();
  }

  changeStatus(status) {
    status == 1 ? status = "Ativo" : status = "Inativo";
    return status;
  }

  maskPhone(texto) {
    if (!texto)
      return;

    texto = texto.toString().replace(/[^\d]/g, '');
    if (texto.length > 0) {
      texto = "(" + texto;
      if (texto.length > 3) {
        texto = [texto.slice(0, 3), ") ", texto.slice(3)].join('');
      }
      if (texto.length > 12) {
        if (texto.length > 13)
          texto = [texto.slice(0, 10), "-", texto.slice(10)].join('');
        else
          texto = [texto.slice(0, 9), "-", texto.slice(9)].join('');
      }
      if (texto.length > 15)
        texto = texto.substr(0, 15);
    }
    return texto;
  }

  maskCpf(texto) {
    if (!texto) return;

    texto = texto.toString().replace(/[^\d]/g, '');

    texto = texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
    function( regex, argumento1, argumento2, argumento3, argumento4 ) {
           return argumento1 + '.' + argumento2 + '.' + argumento3 + '-' + argumento4;
   })  
    return texto;
  }

  secondsToDate(segundos) {
    let momentDuration = moment.duration(segundos, 'seconds')
    let dataFromSegundos = momentDuration.format("HH:mm:ss");
    return dataFromSegundos;
  }

  handleSwitch(valor) {
    let retornoValor = true;
    retornoValor = valor == 0 ? false : true;
    retornoValor = retornoValor ? 1 : 0;
    return retornoValor;
  }

  isEmptyOrUndefined(value) {
    return (value === "" || value === undefined);
  }

  isNotANumber(value) {
    return isNaN(Number(value));
  }

  copyObjectWithoutReferencing(obj){
    let files = {};
    let values = {};

    Object.entries(obj).forEach(x => {
      if(x[1] instanceof Array){
        if(x[1].length > 0 && x[1][0] instanceof File){
          files[x[0]] = x[1];
          return;
        } 
        values[x[0]] = x[1];
      } else {
        values[x[0]] = x[1];
      }
    });

    let newObject = JSON.parse(JSON.stringify(values));

    return {...newObject, ...files};
  }

}

export default new Utils();