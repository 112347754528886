import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import AdminLayout from "../layouts/Admin/Admin.jsx";
import { 
    Api, 
    Utils, 
    MobsCardCollapse, 
    MobsTable, 
    MobsButton  
} from "../mobscomponents";

class Modalidades extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: [],
            tableMsg: 'Carregando...',
        };
    }

    componentDidMount() {
        Api.req('Modalidade/Get', null)
            .then((e) => {
                if (e.status) {
                    this.setState({ 
                        dataset: e.retorno, 
                        tableMsg: 'Nenhum dado encontrado', 
                    });
                } else {
                    this.setState({ tableMsg: 'Nenhum dado encontrado' });
                    Utils.alert("Oops..", e.msg);
                }
            })
            .catch((e) => {
                this.setState({ tableMsg: 'Nenhum dado encontrado' });
                Utils.alert("Oops..", e.toString());
            });
    }

    handleDeleteButtonClick = (value, row) => {
        Utils.alertConfirm(
            "Atenção",
            `Confirmar exclusão da modalidade '${row.NomeCompleto}'?`,
            'warning',
            "Confirmar",
            "Não",
            (confirm) => {
                if (confirm) {
                    Utils.loading();
                    Api.req('Modalidade/Remove', null, [{ key: 'Codigo', value: value }])
                        .then((e) => {
                            Utils.cancelLoading();
                            if (e.status) {
                                let dataset = this.state.dataset.filter(u => u.Codigo !== value);
                                this.setState({ dataset });

                                Utils.alert("Yay!", e.msg, 'success');
                            } else {
                                Utils.alert("Oops..", e.msg);
                            }
                        })
                        .catch((e) => {
                            Utils.alert("Oops..", e.toString());
                        });
                }
            }
        );
    };

    createButton = (value, row) => {
        return <div>
            <MobsButton
                tooltip={"Editar Modalidade"}
                path={`/modalidades-formulario/${value}`}
                color={"info"}
                size={"sm"}
            >
                <i className="tim-icons icon-pencil" />
            </MobsButton>

            <MobsButton
                tooltip={"Excluir Modalidade"}
                onClick={() => this.handleDeleteButtonClick(value, row)}
                color={"danger"}
                size={"sm"}
            >
                <i className="tim-icons icon-simple-remove" />
            </MobsButton>
        </div>;
    }

    render() {
        const optionsPage = {
            hideSizePerPage: true,
            noDataText: this.state.tableMsg
        };

        return (
            <>
                <AdminLayout
                    {...this.props}
                    content={
                        <div className="content">
                            <Row>
                                <Col md="12">
                                    <MobsCardCollapse
                                        isStatic
                                        title={<>Modalidades cadastradas
                                             <MobsButton
                                                className="btn-simple float-right"
                                                path={`/modalidades-formulario`}
                                                color={"info"}
                                                size={"sm"}
                                            >Cadastrar</MobsButton>
                                        </>
                                        }
                                        body={
                                            <>
                                                <MobsTable
                                                    pagination
                                                    dataSet={this.state.dataset}
                                                    tableFields={[
                                                        { key: "Codigo", label: "Código", width: '10%', placeholder: "Pesquisar Código", dataSort: true, isKey: true  },
                                                        { key: "Descricao", label: "Descrição", placeholder: "Pesquisar Descrição", dataSort: true },
                                                        { key: "Codigo", label: "#", dataAlign: 'right', width: '10%', type: "buttons", createButtons: this.createButton.bind(this) },
                                                    ]}
                                                    optionsPage={optionsPage}
                                                />
                                            </>
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>}
                />
            </>
        );
    }
}

export default Modalidades;
