import { withFormik } from "formik";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import * as yup from "yup";
import AdminLayout from "../layouts/Admin/Admin.jsx";
import {
  Api,
  MobsButton,
  MobsCardCollapse,
  MobsComponentForm,
  MobsInput,
  Utils
} from "../mobscomponents";

const apiEndpoint = {
  create: "Notifications/Set",
};

class Notification extends MobsComponentForm {
  constructor(props) {
    super(props);

    this.initializeEndpoints(apiEndpoint);
  }

  render() {
    const {
      values,
      errors,
      handleChange,
      handleSubmit,
    } = this.props;

    return (
      <>
        <AdminLayout
          {...this.props}
          content={
            <div className="content">
              <Row>
                <Col md="12">
                  <Form onSubmit={handleSubmit}>
                    <MobsCardCollapse
                      isStatic
                      title={
                        <>
                          <MobsButton
                            className="btn-back"
                            onClick={() => this.props.history.push("/horses")}
                          >
                            <i className="tim-icons icon-minimal-left" />
                          </MobsButton>
                          <h5 className="title">
                            Enviar Notificação
                          </h5>
                        </>
                      }
                      body={
                        <>
                          <Row>
                            <Col className="pr-md-3" md="12">
                              <FormGroup>
                                <label>Título</label>
                                <MobsInput
                                  type="text"
                                  name="Titulo"
                                  value={values.Titulo}
                                  placeholder="Digite o título da notificação"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pr-md-3" md="12">
                              <FormGroup>
                                <label>Mensagem</label>
                                <MobsInput
                                  type="text"
                                  name="Mensagem"
                                  value={values.Mensagem}
                                  placeholder="Digite a mensagem da notificação"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      }
                    />

                    <Button className="btn-fill" color="primary" type="submit">
                      Salvar
                    </Button>
                  </Form>
                </Col>
              </Row>
            </div>
          }
        />
      </>
    );
  }
}

const formikForm = withFormik({
  mapPropsToValues: () => ({}),

  validateOnChange: false,

  validationSchema: yup.object().shape({
    Titulo: yup.string().required("Este campo é obrigatório"),
    Mensagem: yup.string().required("Este campo é obrigatório"),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    setSubmitting(true);

    let newValues = Utils.copyObjectWithoutReferencing(values);

    let endpoint = apiEndpoint.create;

    Api.req(endpoint, null, Utils.objToKeyVal(newValues))
      .then((resp) => {
        if (resp.status) {
          Utils.alertFormSuccess(resp.msg, () => {
            props.history.replace("/notificacao");
          });
        } else {
          setSubmitting(false);
          Utils.alert(resp.msg);
        }
      })
      .catch((e) => {
        setSubmitting(false);
        Utils.alert(e.message);
      });
  },
})(Notification);

export default withRouter(formikForm);
