/* eslint-disable */

import React from "react";
import {Route} from "react-router-dom";
// javascript plugin used to create scrollbars on windows

import routes from "../routes.js";


class MobsGeneralComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: "primary",
            sidebarOpened:
                document.documentElement.className.indexOf("nav-open") !== -1,
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate(e) {
    }

    // this function opens and closes the sidebar on small devices
    toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        this.setState({sidebarOpened: !this.state.sidebarOpened});
    };
    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/administrativo") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    handleBgClick = color => {
        this.setState({backgroundColor: color});
    };

    getBrandText = path => {
        for (let i = 0; i < routes.length; i++) {
            if (
                this.props.location.pathname.indexOf(
                    routes[i].layout + routes[i].path
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        return "Brand";
    };


}

export default MobsGeneralComponent;
