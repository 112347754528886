import React, { PureComponent } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import { Input, FormFeedback } from "reactstrap";
import pt from 'date-fns/locale/pt';

registerLocale('pt', pt);


export default class MobsDatePicker extends PureComponent {
  render() {
    let name = this.props.name;
    let isClearable = this.props.isClearable;

    let value = !isClearable ? moment().toDate() : undefined;
    if (this.props.value) {
      value = moment(this.props.value).toDate();
    }

    let errors = this.props.errors ? this.props.errors[name] : null;

    return (
      <>
        <DatePicker
          isClearable={isClearable}
          selected={value}
          maxDate={this.props.maxDate}
          minDate={this.props.minDate}
          onChange={(date) => this.props.setFieldValue(this.props.name, date != null ? moment(date).format("YYYY-MM-DD HH:mm:ss") : undefined)}
          locale="pt"
          dateFormat={!this.props.dateFormat && "dd/MM/yyyy"}
          todayButton={this.props.todayButtonText}
          placeholderText={this.props.placeholder}
          shouldCloseOnSelect={this.props.shouldCloseOnSelect}
          customInput={<Input />}
        />
        {
          errors ? <FormFeedback style={{ display: 'block' }}> {(!!errors) && errors} </FormFeedback>
            :
            <label style={{ color: "#bbbbbb", display: 'block', marginLeft: 10 }}> {this.props.helper} </label>
        }
      </>
    );
  }
}