/* eslint-disable */
import React, { Component } from "react";
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Collapse,
} from "reactstrap";
import Switch from "react-bootstrap-switch";

export default class MobsCardCollapse extends Component {

  isStatic = () => this.props.isStatic || false;

  render(){
    let value = !!this.props.value;

    return (
      <div>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              <Row>
                <Col className="pr-md-3" md={!this.isStatic() ? "10" : "12"}>{this.props.title}</Col>
                {
                !this.isStatic() &&
                <Col className="pr-md-3 text-right" md="2">
                  <Switch 
                    bsSize="normal"
                    offColor="info"
                    onColor="success"
                    onText=""
                    offText=""
                    value={value}
                    onChange={(el, state) => {
                      this.props.setFieldValue(this.props.name, state);
                    }} 
                  />
                </Col>
                }
              </Row>
            </CardTitle>
          </CardHeader>

          <Collapse
            isOpen={this.isStatic() || value}
            onEntering={this.props.onEntering}
            onEntered={this.props.onEntered}
            onExiting={this.props.onExiting}
            onExited={this.props.onExited}
          >
            <CardBody>
              {this.props.body}
            </CardBody>

            <CardFooter>
              {this.props.footer}
            </CardFooter>
          </Collapse>
        </Card>
      </div>
    );
  }
}