/* eslint-disable */
import Prefs from './Prefs';

// const host = "http://localhost:8080/";
const host = "https://api.bookofstallion.com.br/";

class Api {
    req(url, data, customData = [], method = 'POST') {
        let d = new FormData();
        if (data !== null) {
            d = new FormData(data);
        }

        d.append('tk', 'voidweb_!');
        customData.map((v, key) => {
            d.append(v.key, v.value);
        });

        return new Promise((resolve, reject) => {
            fetch(host + url, {
                method: method,
                headers: new Headers({
                    'Authorization': `Bearer ${Prefs.get('token')}`
                }),
                body: d,
            }).then(function (response) {
                response.json().then(e => {
                    if (!e.status && e.retorno === 401) {
                        Prefs.clear();
                        window.location.href = "login";
                    } else {
                        resolve(e);
                    }
                });
            }).catch(function (response) {
                reject(false);
            });
        });
    }
}

export default new Api();