import React from "react";

import Login from "./views/Login.jsx";
import Dashboard from "./views/Dashboard.jsx";
import Users from "./views/Users.jsx";
import UsersForm from "./views/UsersForm.jsx";
import Horses from "./views/Horses";
import HorsesForm from "./views/HorsesForm";
import Notification from "./views/Notification";
import ModalidadesForm from "./views/ModalidadesForm";
import Modalidades from "./views/Modalidades";
import Racas from "./views/Racas";
import RacasForm from "./views/RacasForm";
import Leads from "./views/Leads";
import Parceiros from "./views/Parceiros";
import ParceirosForm from "./views/ParceirosForm";

const layout = "/stallion";

let routes = [
  {
    private: false,
    path: "/login",
    name: "Login",
    icon: "tim-icons icon-user",
    component: Login,
    render: <Login />,
    layout,
    showmenu: false,
  },
  {
    private: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    render: <Dashboard />,
    layout,
    showmenu: true,
  },
  {
    private: true,
    path: "/usuarios",
    name: "Usuários",
    icon: "tim-icons icon-single-02",
    component: Users,
    render: <Users />,
    layout,
    showmenu: true,
  },
  {
    private: true,
    path: ["/usuarios-formulario/:id", "/usuarios-formulario"],
    name: "Formulário Usuário",
    icon: "tim-icons icon-single-02",
    component: UsersForm,
    render: <UsersForm />,
    layout,
    showmenu: false,
  },
  {
    private: true,
    path: "/modalidades",
    name: "Modalidades",
    icon: "tim-icons icon-single-02",
    component: Modalidades,
    render: <Modalidades />,
    layout,
    showmenu: true,
  },
  {
    private: true,
    path: ["/modalidades-formulario/:id", "/modalidades-formulario"],
    name: "Formulário Modalidade",
    icon: "tim-icons icon-single-02",
    component: ModalidadesForm,
    render: <ModalidadesForm />,
    layout,
    showmenu: false,
  },
  {
    private: true,
    path: "/racas",
    name: "Raças",
    icon: "tim-icons icon-single-02",
    component: Racas,
    render: <Racas />,
    layout,
    showmenu: true,
  },
  {
    private: true,
    path: ["/racas-formulario/:id", "/racas-formulario"],
    name: "Formulário Raças",
    icon: "tim-icons icon-single-02",
    component: RacasForm,
    render: <RacasForm />,
    layout,
    showmenu: false,
  },
  {
    private: true,
    path: "/leads",
    name: "Leads",
    icon: "tim-icons icon-single-02",
    component: Leads,
    render: <Leads />,
    layout,
    showmenu: true,
  },
  {
    private: true,
    path: "/horses",
    name: "Cavalos",
    icon: "tim-icons icon-bag-16",
    component: Horses,
    render: <Horses />,
    layout,
    showmenu: true
  },
  {
    private: true,
    path: ["/horses-formulario/:id", "/horses-formulario"],
    name: "Formulário Cavalos",
    icon: "tim-icons icon-single-02",
    component: HorsesForm,
    render: <HorsesForm />,
    layout,
    showmenu: false
  },
  {
    private: true,
    path: "/parceiros",
    name: "Parceiros",
    icon: "tim-icons icon-single-02",
    component: Parceiros,
    render: <Parceiros />,
    layout,
    showmenu: true,
  },
  {
    private: true,
    path: ["/parceiros-formulario/:id", "/parceiros-formulario"],
    name: "Formulário Parceiros",
    icon: "tim-icons icon-single-02",
    component: ParceirosForm,
    render: <ParceirosForm />,
    layout,
    showmenu: false,
  },
  {
    private: true,
    path: "/notificacao",
    name: "Notificação",
    icon: "tim-icons icon-bag-16",
    component: Notification,
    render: <Notification />,
    layout,
    showmenu: true
  },
];

export default routes;
