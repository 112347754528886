import React, { Component } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

export default class MobsButton extends Component {
  render() {
    return (
      <Link to={{
        pathname: this.props.path,
      }}>
        <Button
          title={this.props.tooltip}
          onClick={this.props.onClick}
          className={this.props.className ? this.props.className : "btn-simple" }
          color={this.props.color}
          id={this.props.id}
          size={this.props.size}
        >
          {this.props.children}
        </Button>
      </ Link>
    )
  }
}
