import { withFormik } from "formik";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import * as yup from "yup";
import AdminLayout from "../layouts/Admin/Admin.jsx";
import {
  Api,
  MobsButton,
  MobsCardCollapse,
  MobsComponentForm,
  MobsDropzone,
  MobsInput,
  Utils,
} from "../mobscomponents";

const apiEndpoint = {
  detail: "Parceiros/Detail",
  update: "Parceiros/Alter",
  create: "Parceiros/Set",
};

class ParceirosForm extends MobsComponentForm {
  constructor(props) {
    super(props);

    this.initializeEndpoints(apiEndpoint);
  }

  render() {
    const {
      values,
      errors,
      handleChange,
      handleSubmit,
      setFieldValue,
    } = this.props;

    return (
      <>
        <AdminLayout
          {...this.props}
          content={
            <div className="content">
              <Row>
                <Col md="12">
                  <Form onSubmit={handleSubmit}>
                    <MobsCardCollapse
                      isStatic
                      title={
                        <>
                          <MobsButton
                            className="btn-back"
                            onClick={() => this.props.history.push("/parceiros")}
                          >
                            <i className="tim-icons icon-minimal-left" />>
                          </MobsButton>
                          <h5 className="title">
                            {this.props.match.params.id
                              ? "Alterar"
                              : "Cadastrar"}{" "}
                            Parceiros
                          </h5>
                        </>
                      }
                      body={
                        <>
                          <Row>
                            <Col className="pr-md-3" md="6">
                              <FormGroup>
                                <label>Titulo</label>
                                <MobsInput
                                  type="text"
                                  name="Titulo"
                                  value={values.Titulo}
                                  placeholder="Digite o Titulo"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-md-3" md="6">
                              <FormGroup>
                                <label>Link</label>
                                <MobsInput
                                  type="text"
                                  name="Link"
                                  value={values.Link}
                                  placeholder="Digite o Link"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="pr-md-3" md="12">
                              <FormGroup>
                                <label>Descrição</label>
                                <MobsInput
                                  type="text"
                                  name="Descricao"
                                  value={values.Descricao}
                                  placeholder="Digite a descrição"
                                  onChange={handleChange}
                                  errors={errors}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="pr-md-3" md="12">
                              <FormGroup>
                                <label>Imagem</label>
                                <MobsDropzone
                                  name={`imagem`}
                                  maxSize={700000000}
                                  accept="image/jpeg, image/png"
                                  maxFiles={1}
                                  maxFilesMessage="É permitido subir apenas 1 imagem"
                                  value={values.imagem}
                                  preview={values.imagem_preview}
                                  previewName={"imagem_preview"}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                >
                                  <p>Arraste ou Clique para fazer upload da imagem</p>
                                </MobsDropzone>
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      }
                    />

                    <Button className="btn-fill" color="primary" type="submit">
                      Salvar
                    </Button>
                  </Form>
                </Col>
              </Row>
            </div>
          }
        />
      </>
    );
  }
}

const formikForm = withFormik({
  mapPropsToValues: () => ({}),

  validateOnChange: false,

  validationSchema: yup.object().shape({
    Titulo: yup.string().required("Este campo é obrigatório"),
    Descricao: yup.string().required("Este campo é obrigatório"),
    Link: yup.string().required("Este campo é obrigatório"),
    imagem: yup.mixed().when(
      'content_file_preview', {
      is: (val) => val === undefined,
      then: yup.mixed().required("Este campo é obrigatório")
    }),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    setSubmitting(true);

    let newValues = Utils.copyObjectWithoutReferencing(values);

    let endpoint = apiEndpoint.create;
    if (values.Codigo) endpoint = apiEndpoint.update;

    Api.req(endpoint, null, Utils.objToKeyVal(newValues))
      .then((resp) => {
        if (resp.status) {
          Utils.alertFormSuccess(resp.msg, () => {
            props.history.replace("/parceiros");
          });
        } else {
          setSubmitting(false);
          Utils.alert(resp.msg);
        }
      })
      .catch((e) => {
        setSubmitting(false);
        Utils.alert(e.message);
      });
  },
})(ParceirosForm);

export default withRouter(formikForm);
